<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-meduim" :class="isRTL ? 'ml-auto' : 'mr-auto'">
        <app-i18n code="event.view.details"></app-i18n>
      </h2>
    </div>
    <div class="intro-y box p-5 mt-5">
      <div
        class="flex justify-center"
        style="width: 100%; height: 30vh"
        v-if="loading"
      >
        <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
      </div>
      <div v-else>
        <div
          class="flex flex-col lg:flex-row border-b border-gray-200 dark:border-dark-5 pb-5-mx-5"
        >
          <div class="flex-1 px-5 pb-5">
            <div class="w-24 sm:w-40 sm:whitespace-normal font-medium text-lg">
              {{ presenterLocalization(record, 'title') }}
            </div>
            <div
              class="w-56 h-20 sm:w-64 sm:h-24 lg:w-96 lg:h-36 flex-none image-fit relative mt-2"
            >
              <img
                :src="imageEnUrl"
                alt=""
                class="w-full h-full rounded"
                data-action="zoom"
              />
            </div>

            <div
              class="w-56 h-20 sm:w-64 sm:h-24 lg:w-96 lg:h-36 flex-none image-fit relative mt-5"
            >
              <img
                :src="imageArUrl"
                alt=""
                class="w-full h-full rounded"
                data-action="zoom"
              />
            </div>
          </div>
          <div
            class="mt-6 lg:mt-0 flex-1 dark:text-gray-300 px-5 border-l border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0"
          >
            <div
              class="font-medium lg:mt-3"
              :class="isRTL ? 'lg:text-right' : 'lg:text-left'"
            >
              <app-i18n code="event.view.eventDescription"></app-i18n>
            </div>
            <div class="font-medium mt-3 w-96 text-gray-600">
              {{ presenterLocalization(record, 'description') }}
            </div>
            <div
              class="font-medium lg:mt-3"
              :class="isRTL ? 'lg:text-right' : 'lg:text-left'"
            >
              <app-i18n code="event.view.eventLocation"></app-i18n>
            </div>
            <div class="font-medium mt-3 w-96 text-gray-600">
              {{ presenterLocalization(record, 'location') }}
            </div>
            <div
              class="font-medium lg:mt-3"
              :class="isRTL ? 'lg:text-right' : 'lg:text-left'"
            >
              <app-i18n code="event.view.eventTime"></app-i18n>
            </div>
            <div class="font-medium mt-3 w-96 text-gray-600">
              {{ presenterDate(record, 'eventTime') }}
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-center items-center mt-10">
        <router-link
          type="button"
          class="btn bg-theme-31 text-white"
          :to="`/event`"
        >
          <CornerUpLeftIcon class="w-5 h-5" :class="isRTL ? 'ml-2' : 'mr-2'" />
          <app-i18n code="common.back"></app-i18n>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'

export default {
  props: {
    id: {
      type: String,
      required: false
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'event/view/record',
      loading: 'event/view/loading'
    }),
    imageEnUrl() {
      return this.record && this.record.image
        ? this.record.image.en
        : require('@/assets/images/profile-1.jpg')
    },
    imageArUrl() {
      return this.record && this.record.image
        ? this.record.image.ar
        : require('@/assets/images/profile-1.jpg')
    }
  },
  created() {
    document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.event')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.event')
    }
  },
  async mounted() {
    await this.doFind(this.id)
  },
  methods: {
    ...mapActions({
      doFind: 'event/view/doFind'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    presenterInteger(row, fieldName) {
      return GenericModel.presenterInteger(row, fieldName)
    },
    presenterDecimal(row, fieldName) {
      return GenericModel.presenterDecimal(row, fieldName)
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    doCancel() {
      this.$router.push('/event')
    }
  }
}
</script>
